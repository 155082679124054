<template>
  <v-container>
    <v-form @submit.prevent="add" ref="form" lazy-validation v-model="valid">
      <v-row dense>
        <v-col cols="12" xl="6" lg="6" md="6" sm="12">
          <v-text-field
            label="Nome do seguro"
            dense
            outlined
            hide-details
            readonly
            v-model="model.lifeCoverageGroupName"
          >
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    color="primary"
                    v-on="on"
                    @click="showCoverages"
                    id="showCoverages"
                  >
                    fa-solid fa-circle-info
                  </v-icon>
                </template>
                <span>Clique para ver as coberturas!</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" xl="2" lg="2" md="2" sm="6" v-if="canEdit">
          <BaseButton
            type="submit"
            color="primary"
            title="Adicionar"
            height="40"
            :disabled="!valid"
          />
        </v-col>
      </v-row>
    </v-form>

    <BaseTableList class="pt-4" title="Listagem de beneficiários">
      <template v-slot:table>
        <v-data-table
          dense
          item-key="id"
          disable-sort
          hide-default-footer
          :headers="headers"
          :items="model.lifeInsuranceBeneficiaries"
        >
          <template v-slot:[`item.percentage`]="{ item }">
            {{ formatPercentage(item.percentage) }}
          </template>

          <template v-slot:[`item.effectiveDate`]="{ item }">
            {{ formatDate(item.effectiveDate) }}
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom v-if="canEdit">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="success"
                  class="mr-2"
                  small
                  @click="edit(item)"
                >
                  fa-solid fa-pencil
                </v-icon>
              </template>
              <span>Visualizar</span>
            </v-tooltip>

            <v-tooltip bottom v-if="canEdit">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="error"
                  small
                  @click="confirmeDelete(item)"
                >
                  fa-regular fa-trash-can
                </v-icon>
              </template>
              <span>Excluir</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>

    <v-divider class="my-5" />

    <v-row>
      <v-col class="text-right">
        <BaseButton @click="back" outlined color="primary" title="Voltar" />

        <template v-if="canEdit">
          <BaseButton
            @click="save"
            color="primary"
            title="Salvar"
            :disabled="model.lifeInsuranceBeneficiaries.length <= 0"
            v-if="percentage === 100"
          />
        </template>
      </v-col>
    </v-row>

    <DialogLife
      v-if="dialog"
      :dialog="dialog"
      :title="dialogTitle"
      :edit="dialogEdit"
      :selectItem="selectItem"
      @closeModal="closeModal"
    />

    <DialogCoverages
      v-if="dialogCoverage"
      :dialog="dialogCoverage"
      :items="model.coverages"
      @closeModal="closeModal"
    />
  </v-container>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { mapGetters } from 'vuex';
import { formatPercentage, formatDate } from '@/helpers/formatting';
import { MovementService } from '@/services/api/movements';
import { confirmMessage, showMessage } from '@/helpers/messages';

export default {
  mixins: [rulesMixin],

  components: {
    DialogLife: () => import('./dialog-life'),
    DialogCoverages: () => import('./dialog-coverages')
  },

  props: {
    employeeId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    dialog: false,
    dialogEdit: false,
    dialogTitle: '',
    selectItem: {},
    valid: true,
    headers: [
      { text: 'Beneficiário', value: 'name' },
      { text: 'Início de vigência', value: 'effectiveDate' },
      { text: 'Parentesco', value: 'kinship' },
      { text: 'Percentual', value: 'percentage' },
      { text: '', value: 'action' }
    ],
    dialogCoverage: false,

    model: {
      productType: null,
      lifeInsuranceBeneficiaries: []
    }
  }),

  computed: {
    ...mapGetters({
      employee: 'movements/getEmployee'
    }),

    canEdit() {
      const situation =
        this.employee.situation === 'Ativo' ||
        this.employee.situation === 'Em análise na operadora' ||
        this.employee.situation === null;

      return situation;
    },

    percentage() {
      const total = this.model.lifeInsuranceBeneficiaries.map(item =>
        parseFloat(item.percentage)
      );

      return total.reduce((a, b) => a + b, 0);
    }
  },

  mounted() {
    this.search();
  },

  methods: {
    formatPercentage,
    formatDate,

    async search() {
      try {
        const movementService = new MovementService();
        const { status, data } = await movementService.getEmployeePlan({
          employeeId: this.employeeId,
          productType: 'Seguro de vida'
        });

        if (status === 200) {
          this.model = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    back() {
      this.$emit('back');
    },

    add() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      this.dialog = true;
      this.dialogEdit = false;
      this.dialogTitle = 'Adicionar beneficiário';
      this.selectItem = {};
    },

    edit(item) {
      this.dialog = true;
      this.dialogEdit = true;
      this.dialogTitle = 'Visualizar beneficiário';
      this.selectItem = Object.assign({}, item);
    },

    async confirmeDelete(payload) {
      const result = await confirmMessage(
        'Atenção!',
        'Deseja realmente excluir o registro selecionado?'
      );

      if (result) {
        const index = this.model.lifeInsuranceBeneficiaries.findIndex(
          x => x.id === payload.id
        );

        if (index !== -1) {
          this.model.lifeInsuranceBeneficiaries.splice(index, 1);
        }
      }
    },

    closeModal(payload) {
      this.dialog = false;
      this.dialogEdit = false;
      this.dialogTitle = '';
      this.selectItem = {};

      this.dialogCoverage = false;

      if (payload) {
        this.addBeneficiaries(payload);
      }
    },

    addBeneficiaries(payload) {
      const index = this.model.lifeInsuranceBeneficiaries.findIndex(
        x => x.id === payload.id
      );

      if (index !== -1) {
        Object.assign(this.model.lifeInsuranceBeneficiaries[index], payload);
      } else {
        this.model.lifeInsuranceBeneficiaries.push(payload);
      }
    },

    async save() {
      const params = Object.assign({}, this.model);
      params.employeeId = this.employeeId;
      params.productType = 'Seguro de vida';

      const percentages = params.lifeInsuranceBeneficiaries.map(item =>
        parseFloat(item.percentage)
      );
      const total = percentages.reduce((a, b) => a + b, 0);

      if (total < 100 || total > 100) {
        showMessage(
          'error',
          'Para salvar é preciso incluir beneficiários até atingir 100% do percentual'
        );
      } else {
        try {
          const movementService = new MovementService();
          const { status } = await movementService.saveEmployeePlan(params);

          if (status === 204) {
            showMessage('success', 'Operação realizada com sucesso');
            this.search();
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    },

    showCoverages() {
      this.dialogCoverage = true;
    }
  }
};
</script>
